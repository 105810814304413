<template>
	<div class="container">
		<div class="mainTitle2">
			<div class="mainTitle2__titleWithLink" v-if="content.link">
				<p class="h2" v-html="content.title"></p>
				<router-link class="mainTitle2--link" :to="content.link" v-html="content.linkText" v-if="content.link"></router-link>
			</div>
			<p class="h2" v-html="content.title" v-else></p>

			<p class="mainTitle2--desc color-gray" v-html="content.description"></p>
		</div>

		<div class="outStuff">
			<div class="outStuff__left">
				<div class="outStuff__items">
					<div v-for="item in content.items" class="animationBlockFadeUp">
						<p class="outStuff--title" v-html="item.title"></p>
						<p class="outStuff--desc" v-if="item.description" v-html="item.description"></p>
					</div>
				</div>
				<p class="color-gray" v-html="content.itemsDescription"></p>
			</div>
			<div class="outStuff__right">
				<VueSlickCarousel :class="[{'slick-one-slide': content.reviews.length<2}, 'outStuffSlider', 'animationBlockFadeUp']" ref="slider" v-bind="slickSettings">
					<div class="outStuffSlider__item" v-for="slide in content.reviews">
						<div class="outStuffSlider__logo" v-html="slide.logo" v-if="slide.logo"></div>
						<div class="outStuffSlider__text" v-html="slide.text"></div>
						<div class="outStuffSlider__author" v-if="slide.author">
							<div class="outStuffSlider__authorImg" v-lazy:background-image="slide.author.img" v-if="slide.author.img"></div>
							<div class="outStuffSlider__authorContent">
								<p class="outStuffSlider--name" v-if="slide.author.name" v-html="slide.author.name"></p>
								<p class="outStuffSlider--desc" v-if="slide.author.description" v-html="slide.author.description"></p>
							</div>
						</div>
					</div>
					<template #customPaging="page">
						<div class="dot"></div>
					</template>
				</VueSlickCarousel>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/OutStuff'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	export default {
		props: ['content'],
		data: () => ({
			slickSettings: {
				dots: true,
				arrows: false,
				infinite: true
			}
		}),
		components: {
			VueSlickCarousel
		}
	}
</script>